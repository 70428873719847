import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import CoverImage from '../elements/CoverImage'
import ServiceSection from '../sections/ServiceSection'
  
const { text } = require('../content/content')
const ServicePage = () => {
    
    const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "services.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 2160, toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return (
    <div>
      <CoverImage
        H={100}
        imageSource={placeholderImage.childImageSharp.fluid}
        text={text.service.headline}
      />
      <ServiceSection />
    </div>
  )
}

  export default ServicePage