import React from 'react'
import Img from 'gatsby-image'

import { s, global, colors } from '../style/style'
import TextContainer from './TextContainer'

const CoverImage = ({ imageSource, text, H = 100 }) => {
  const style = {
    container: {
      position: 'relative',
      display: 'inline-block',
      width: '100vw',
      height: `calc(${H}vh + 2px)`,
      [s.xs]: { marginTop: `-${global.navbarHeight.mobile}px` },
      [s.sm]: { marginTop: `-${global.navbarHeight.desktop}px` },
      background: `linear-gradient(to bottom, ${colors.main.background}00 60%, ${colors.main.background} 96%)`,
    },
    imgStyle: {
      zIndex: -1,
    },
  }

  return (
    <div css={style.container}>
      <Img
        imgStyle={style.imgStyle}
        fluid={imageSource}
        style={{ height: `${H}vh` }}
      />
      <TextContainer text={text} />
    </div>
  )
}

export default CoverImage
