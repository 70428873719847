import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

// const CoverImage = lazy(() => import('../elements/CoverImage'))
// const AboutSection = lazy(() => import('../sections/AboutSection'))

import CoverImage from '../elements/CoverImage'
import AboutSection from '../sections/AboutSection'

const { text } = require('../content/content')

const AboutPage = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 2160, toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <CoverImage
        H={100}
        imageSource={placeholderImage.childImageSharp.fluid}
        text={text.about.headline}
      />
      <AboutSection />
    </div>
  )
}

export default AboutPage
