import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import CoverImage from '../elements/CoverImage'
import PortfolioSection from '../sections/PortfolioSection'

// const CoverImage = lazy(() => import('../elements/CoverImage'))
// const PortfolioSection = lazy(() => import('../sections/PortfolioSection'))

const { text } = require('../content/content')

const PortfolioPage = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "portfolio.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 2160, toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <CoverImage
        H={100}
        imageSource={placeholderImage.childImageSharp.fluid}
        text={text.portfolio.headline}
      />
      <PortfolioSection />
    </div>
  )
}

export default PortfolioPage
