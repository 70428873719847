import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import CoverImage from '../elements/CoverImage'
import TechSection from '../sections/TechSection'

// const CoverImage = lazy(() => import('../elements/CoverImage'))
// const TechSection = lazy(() => import('../sections/TechSection'))

const { text } = require('../content/content')

const TechPage = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tech.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 2160, toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <CoverImage
        H={100}
        imageSource={placeholderImage.childImageSharp.fluid}
        text={text.tech.headline}
      />
      <TechSection />
    </div>
  )
}

export default TechPage
