import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import CoverImage from '../elements/CoverImage'
import ContactSection from '../sections/ContactSection'

// const CoverImage = lazy(() => import('../elements/CoverImage'))
// const ContactSection = lazy(() => import('../sections/ContactSection'))

const { text } = require('../content/content')

const ContactPage = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 2160, toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <CoverImage
        H={100}
        imageSource={placeholderImage.childImageSharp.fluid}
        text={text.contact.headline}
      />
      <ContactSection />
    </div>
  )
}

export default ContactPage
