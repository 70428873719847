import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

// Pages
import AboutPage from './AboutPage'
import ContactPage from './ContactPage'
import CoursesPage from './CoursesPage'
import PortfolioPage from './PortfolioPage'
import ServicesPage from './ServicesPage'
import ServicePage from './ServicePage'
import StartPage from './StartPage'
import TechPage from './TechPage'
import PrivacyPage from './PrivacyPage'
import WynajemPage from './WynajemPage'
import ShootingPage from './ShootingPage'
import EventPage from './EventPage'
import VideoPage from './VideoPage'

const Pages = {
  AboutPage,
  ContactPage,
  CoursesPage,
  PortfolioPage,
  ServicesPage,
  ServicePage,
  StartPage,
  TechPage,
  PrivacyPage,
  WynajemPage,
  VideoPage,
  EventPage,
  ShootingPage,
}

const _subpages = ({ pageContext }) => {
  if (typeof Pages[pageContext.componentName] !== 'undefined')
    return (
      <Layout>
        <SEO title={pageContext.title} description={pageContext.description} />
        <div css={stylePage}>
          {React.createElement(Pages[pageContext.componentName], {})}
        </div>
      </Layout>
    )

  return React.createElement(() => (
    <Layout>
      <SEO
        title={pageContext.title || 'ERROR - no title set'}
        description={pageContext.description || 'ERROR - no description set'}
      />
      <div css={stylePage}>
        Component
        <b> {pageContext.componentName || 'ERROR - no component name set!'} </b>
        | name
        <b>
          <i> {pageContext.name || 'ERROR - no name set!'} </i>
        </b>
        @ URL:
        <b>
          <i> {pageContext.link || 'ERROR - no link set!'} </i>
        </b>
        nie został stworzony
      </div>
    </Layout>
  ))
}

const stylePage = {
  minHeight: '40vh',
}

export default _subpages
